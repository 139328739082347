/* #light{
    background-color: #fff;
    color: rgb(32, 32, 32);
} */

#dark{
    background-color: rgb(32, 32, 32);
    color:#fff
}

*{
    font-family:Helvetica;
    margin: 0;
}

#dark #themeIndexName{
    color:rgba(255, 255, 255, 0.664);
}

#dark #themeIconName{
    color: rgba(255, 255, 255, 0.664);

}

#light #themeIconName {
    color: rgba(32, 32, 32,0.8);

}

#dark #productCardTheme{
    background-color: rgb(24, 24, 24);
}

#deliveryWrapper{
    background-color: rgba(107, 166, 168, 0.664);
    border-radius: 15px;
}

#dark #darktThemeTextColor {
    color: #fff;

}

#light #themesSubscriptColor{
    color: #888888;
}

#dark #themesSubscriptColor {
    color: #bebebe;
}

#homeButton{
    padding-right: 1.5rem;
    padding-left: 1.5rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    background-color:inherit;
    border-radius: 3px;
    border:1px solid rgba(180, 180, 180, 0.575);
}

#dark #homeButton {
    border-color: rgba(255, 255, 255, 0.678);
    color: rgba(255, 255, 255,1);
    background-color: rgb(24, 24, 24);
}

#homeButton:hover{
    background-color: rgb(250, 189, 99);
}

#dark #homeButton:hover {
    background-color: rgb(41, 65, 88);
}